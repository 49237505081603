import Vue from 'vue'
import { abandonApplication } from '@/services/api'
import { ApiErrorHandler } from '@/utils/exception-handler'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import assert from 'assert'
import { inspect, logger } from '@/utils/logger'
import { assertCtxNotType } from '@/utils/assert-helper'
import { AxiosError } from 'axios'

export default Vue.extend({
    data() {
        const priorApplicationResponseData = appSessionStorage.getItem(localStorageKey.priorApplicationFoundResponseJSON)
        assert(priorApplicationResponseData, 'No prior application data found in session storage')
        const priorApplicationResponse = JSON.parse(priorApplicationResponseData)

        return {
            returnToken2: priorApplicationResponse.returnToken2,
            priorStatus: priorApplicationResponse.priorStatus,
            priorLoanApplicationId: priorApplicationResponse.priorLoanApplicationId,
            needsDOBValidation: !!priorApplicationResponse.needsDOBValidation,
            isPriorityApplication: !!priorApplicationResponse.isPriorityApplication,
            errorText: '',
            loading: true,
        }
    },
    methods: {
        onContinuePrior: async function () {
            logger.info('Continuing prior application')
            try {
                if (this.loading) return
                this.loading = true
                this.$logEvent('click_button_continue_prior_application')

                assertCtxNotType(this, 'isCoApplicant', 'undefined')
                // @ts-ignore assert takes care of this
                await abandonApplication({ wantsToAbandonCurrentApplication: true, returnToken2ForPriorApplication: this.returnToken2, isCoApplicant: this.isCoApplicant })
                logger.log(`Continuing to prior application using returnToken2: ${this.returnToken2}`)
                window.location.href = `/a/${this.returnToken2}`
            } catch (e) {
                logger.warn(`An error occurred when trying to continue prior application using returnToken2: ${this.returnToken2}. ${inspect(e as AxiosError)}`)
                this.errorText = ApiErrorHandler(e)
                this.loading = false
            }
        },
        onContinueCurrent: async function () {
            logger.info('Continuing current new application')
            try {
                if (this.loading) return
                this.loading = true
                this.$logEvent('click_button_continue_current_application')

                assertCtxNotType(this, 'isCoApplicant', 'undefined')
                // @ts-ignore assert takes care of this
                await abandonApplication({ wantsToAbandonCurrentApplication: false, returnToken2ForPriorApplication: this.returnToken2, isCoApplicant: this.isCoApplicant })
                assertCtxNotType(this, 'pathAfterNextOne', 'undefined')
                // @ts-ignore assert takes care of this
                logger.log(`Continuing current new application to path: ${this.pathAfterNextOne}`)
                // @ts-ignore assert takes care of this
                await this.$router.replace(this.pathAfterNextOne)
            } catch (e) {
                logger.warn(`An error occurred when trying to continue current application. ${inspect(e as Object)}`)
                this.errorText = ApiErrorHandler(e as AxiosError)
                this.loading = false
            }
        },
    },
})
